/* eslint-disable */
export default function loadPendoScript(key, userId, email) {
    const script = document.createElement('script');
  
    let rootSite = window.location.hostname;
  
    script.onload = (function (apiKey) {
      (function (p, e, n, d, o) {
        let v; let w; let x; let y; let
          z; o = p[d] = p[d] || {}; o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          }(v[w]));
        }
        y = e.createElement(n); y.async = !0; y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      }(window, document, 'script', 'pendo'));
  
      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      pendo.initialize({
        visitor: {
          id: userId,
          email,              
        },
        account: {
          id: rootSite,
        },
      });
      pendo.updateOptions({
        visitor: {
          id: userId,
          email,
        },
        account: {
          id: rootSite,
        }
      });
    }(key));
    document.head.appendChild(script);
  }
  